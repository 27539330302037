export const disclaimerPage = {
  title: 'ACRE DAOs Protocol Disclaimer',
  sections: [
    {
      paragraph: `ACRE DAOs is a Web3 portal that people can use to form decentralized autonomous organizations (DAOs) for the purpose of sourcing and making IRL impact investments using virtual assets and non-custodial peer-to-peer transaction networks. Parts of the ACRE DAOs Protocol are made up of free, public, open-source or source-available software including a set of smart contracts that are deployed on the Ethereum Blockchain. Your use of the ACRE DAOs Protocol involves various risks, including, but not limited to, losses while digital assets are being supplied to the ACRE DAOs Protocol and losses due to the fluctuation of value or utility of tokens gifted or earned through your use of ACRE DAOs. Before using ACRE DAOs, you should review the relevant documentation to make sure you understand how the ACRE DAOs Protocol works. Additionally, just as you can access email protocols such as SMTP through multiple email clients, you can access the ACRE DAOs Protocol through dozens of web or mobile interfaces. You are responsible for doing your own diligence on those interfaces to understand the fees and risks they present.`,
    },
    {
      paragraph: `AS DESCRIBED IN THE ACRE DAOs PROTOCOL LICENSES, THE ACRE DAOs PROTOCOL IS PROVIDED “AS IS”, AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. Although Acre of America Onshore LP (“ACREinvest”) developed much of the initial code for the ACRE DAOs Protocol, it does not provide, own, or control the parts of the ACRE DAOs Protocol which are run by smart contracts deployed on the Ethereum blockchain. Upgrades and modifications to the Protocol in the future may be determined in a community-driven way by holders of the ACRE Impact ERC-20 token. No developer or entity involved in creating the ACRE DAOs Protocol will be liable for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of, the ACRE DAOs Protocol, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.`,
    },
  ],
};
