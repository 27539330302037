export const trademarksPage = {
  title: 'ACREinvest Trademark Guidelines',
  sections: [
    {
      paragraph: `Acre of America Onshore LP (“ACREinvest”) distributed the open-source ACRE DAOs Protocol and distributes other software. While our code is in part available to download, review, and improve under open-source and source-available software licenses, none of our licenses include a license to use our trademarks. Proper use of our trademarks is essential toinform users whether or not ACREinvest stands behind a product or service. When using ACREinvest trademarks, you must comply with these ACREinvest Trademark Guidelines. Just like other projects that develop open-source software, we must enforce our trademark rights to protect our users.`,
    },
    {
      paragraph: `This policy covers all of our trademarks and services marks, whether they are registered or not, including, among others:`,
    },
    {
      paragraph: `1. The trademarks and service marks: ACREinvest® and ACRE DAOs™ (“ACREinvest wordmarks”).`,
    },
    {
      paragraph: `2. ACREinvest logos.`,
    },
    {
      paragraph: `If you want to report misuse of an ACREinvest trademark, please contact us at legal@acreofamerica.com`,
    },
  ],
  sections2: [
    {
      header: 'Acceptable Uses',
      body: [
        {
          paragraph:
            'You may do the following withoutreceiving specific permission from ACREinvest:',
        },
        {
          paragraph:
            '• Use ACREinvest wordmarks in text to truthfully refer to and/or link to unmodified ACREinvest smart contracts, protocols, interfaces, programs, products, services and technologies (“ACREinvest software”).',
        },
        {
          paragraph:
            '• Use the ACREinvest wordmarks to truthfully describe modified versions of ACREinvest software that you may create or make available. For example, you may say “This software is derived from ACREinvest software.” or “This service uses software derived from ACREinvest software.',
        },
        {
          paragraph:
            '• Use the ACREinvest logos in software or aggregators that integrate with ACREinvest software to truthfully refer to, and, where possible, link to the applicable ACREinvest software hosted on the Ethereum blockchain.',
        },
        {
          paragraph:
            '• Use ACREinvest wordmarks to clearly signal to users that there is no affiliation with or endorsement by ACREinvest.',
        },
        {
          paragraph:
            '• Follow the terms of the open-source licenses for ACREinvest software.',
        },
      ],
    },
    {
      header: 'When allowed, how can I use an ACREinvest trademark?',
      body: [
        {
          paragraph:
            'You must include a trademark attribution notice at the first or most prominent mention of the mark on a webpage, document or documentation, suchas: “[ACREinvest trademark] is a trademark of Acre of America Onshore LP.',
        },
        {
          paragraph:
            '• You may not change the ACREinvest logos except to scale it. If an ACREinvest logo is used, you must give the ACREinvest logo adequate spacing from the other elements on the webpageor document to ensure legibility and reduce the likelihood of confusion.',
        },
        {
          paragraph:
            '• You must always use the wordmarks in their exact form and with the correct spelling, neither abbreviated, hyphenated, nor combined with any other word or words.',
        },
        {
          paragraph:
            '• You must always use the ACREinvest wordmarks only as an adjective, never as a noun or verb, or in plural or possessive forms.',
        },
        {
          paragraph:
            '     - Instead, use the generic term for the ACREinvest product or service following the trademark.',
        },
        {
          paragraph:
            '     - For example: ACRE DAOs protocol, ACRE DAOs interface, ACREDAOs website, ACRE DAOs portal.',
        },
        {
          paragraph: `All other uses of an ACREinvest trademark require our prior written permission.Contact us at legal@acreofamerica.com for more information.`,
        },
      ],
    },
    {
      header: 'Unacceptable Uses',
      body: [
        {
          paragraph: 'Some specific things you should not do include:',
        },
        {
          paragraph:
            '• Don’t use our trademarks in anything dishonest or fraudulent.',
        },
        {
          paragraph:
            '• Our name is not your name. Don’t use ACREinvest trademarks in the name of your smart contract, interface, business, product, service, app, domain name, publication, or other offering.',
        },
        {
          paragraph:
            '• Don’t use our name for your products. Don’t apply ACREinvest trademarks to any product (e.g., a mobile app), unless such use is limited to a truthful and descriptive reference (e.g., “Mobile App for trading on ACRE DOAs interface”).',
        },
        {
          paragraph:
            'Don’t overemphasize our mark. Don’t display ACREinvest trademarks more prominently than your product, service, or company name.',
        },
        {
          paragraph:
            'Don’t create or use confusingly similar names. Don’t use ACREinvest trademarks, company names, slogans, domain names, or designs that are confusingly similar to ACREinvest trademarks, particularly in the name of your smart contract, interface, business, product, service, app, domain name, publication, or other offering.',
        },
        {
          paragraph: `Don’t imply our sponsorship of your products. Don’t use ACREinvest trademarks in a way that incorrectly implies affiliation with, sponsorship, endorsement, or approval by ACREinvest of your products or services. For example, please do not name your project compatible with ACRE DAOs software ACRE DAOs-[Something] or [Something]-ACRE DAOs.`,
        },
        {
          paragraph: `Don’t imply our sponsorship of your activities. Don’t use ACREinvest trademarks, or confusingly similar trademarks on social media accounts in a way that might suggest affiliation with ACREinvest or ACRE DAOs software; except if you’ve received prior permission from ACREinvest. For example, you cannot name your account, page, or community “ACRE DAOs Protocol” or “ACRE DAOs Team.” However, it would be acceptable to name your account, page, or community “Fans of ACRE DAOs” or ”Information about ACRE DAOs”.`,
        },
        {
          paragraph: `Don’t create swag with ourmarks. Don’t use ACRE DAOs trademarks on merchandise for sale (e.g., selling t-shirts, mugs, etc.) unless you have permission from ACREinvest.`,
        },
        {
          paragraph: `Don’t change or combine our marks. Don’t modify ACREinvest trademarks, abbreviate them, or combine them with anyother symbols, words, or images, or incorporate them into a tagline or slogan.`,
        },
      ],
    },
    {
      header: 'Contact',
      body: [
        {
          paragraph: 'To get in touch, please email legal@acreofamerica.com',
        },
        {
          paragraph:
            'We encourage anyone facing issues with their wallet, transaction or ACRE DAOs related question to contact us via our public social channels.',
        },
      ],
    },
  ],
};
