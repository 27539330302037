import { useMediaQuery, useTheme } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Footer from '../../Footer';
import MetamaskProvider from '../../MetamaskProvider';
import Modal from '../../Modal';
import NavBar from '../../NavBar';
import WrongNetwork from '../../WrongNetwork';
import { Wrapper } from './Style';

interface DesktopLayoutProps {
  children: ReactNode;
}

export default function DesktopLayout({ children }: DesktopLayoutProps) {
  const { account: walletAddress } = useWeb3React();
  const location = useLocation();
  const [tall, setTall] = useState<boolean>(true);
  const [wrongNetwork, setWrongNetwork] = useState<boolean>(false);

  const detectWrongNetwork = useCallback(async () => {
    if (!window || !window?.ethereum) return;
    const chainId = await (window?.ethereum as any).request({
      method: 'eth_chainId',
    });
    if (chainId === '0x1') return;
    // The following one is for GOERLI TESTNET, remove comment while developing
    // if (chainId === '0x5') return;
    return setWrongNetwork(true);
  }, []);

  useEffect(() => {
    if (location.pathname !== '/' && location.pathname !== '/quick-start') {
      setTall(false);
    } else {
      setTall(true);
    }
  }, [location]);

  useEffect(() => {
    if (!walletAddress) return;
    window.localStorage.setItem('connectedWallet', walletAddress as string);
  }, [walletAddress]);

  useEffect(() => {
    detectWrongNetwork();
  }, []);

  return (
    <>
      <MetamaskProvider>
        <NavBar />
        <Wrapper tall={tall}>{children}</Wrapper>
        {!location.pathname.includes('profile') &&
          !location.pathname.includes('admin') && <Footer />}
      </MetamaskProvider>
      <Modal onClose={() => {}} show={wrongNetwork}>
        <WrongNetwork onClose={setWrongNetwork} />
      </Modal>
    </>
  );
}
