import { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BurgerButton from './BurgerButton';
import MobileMenu from './MobileMenu';
import {
  AdminLoginLink,
  LaunchAppButton,
  Logo,
  Nav,
  NavLinks,
  Links,
} from './Style';
import Wallet from '../Wallet';
import { useUserContext } from '../../context/UserContext';
import { ReactComponent as AdminLoginIcon } from '../../assets/icons/admin-login.svg';
import { useMediaQuery, useTheme } from '@material-ui/core';

export default function NavBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState<boolean>(false);
  const [showNavBtn, setShowNavBtn] = useState<boolean>(true);
  const [lightNavbar, setLightNavbar] = useState<boolean>(true);
  const location = useLocation();
  const { isAdmin, name, approved } = useUserContext();

  useEffect(() => {
    if (
      location.pathname === '/dashboard' ||
      location.pathname === '/messages' ||
      location.pathname === '/notifications' ||
      location.pathname === '/activity' ||
      location.pathname === '/search' ||
      location.pathname === '/profile'
    ) {
      setShowNavBtn(false);
    } else {
      setShowNavBtn(true);
    }
  }, [location]);

  useEffect(() => {
    if (
      location.pathname !== '/' &&
      location.pathname !== '/quick-start' &&
      location.pathname !== '/join-us' &&
      location.pathname !== '/about' &&
      location.pathname !== '/cookies' &&
      location.pathname !== '/disclaimer' &&
      location.pathname !== '/trademarks' &&
      location.pathname !== '/privacy' &&
      location.pathname !== '/terms' &&
      location.pathname !== '/mobile-warning'
    ) {
      setLightNavbar(false);
    } else {
      setLightNavbar(true);
    }
  }, [location]);

  return (
    <Nav transparent={lightNavbar}>
      <Link to="/">
        {lightNavbar ? (
          <Logo src="/logo_mini_light.svg" alt="Logo" />
        ) : (
          <Logo src="/logo_dark.svg" alt="Logo" />
        )}
      </Link>
      <NavLinks registered={!!name} isAdmin={isAdmin} lightNavbar={lightNavbar}>
        <Links href="/about">About</Links>
        {!approved && (
          <Links href="https://forum.acredaos.com/" target="_blank">
            Forum
          </Links>
        )}
        <Links href="https://faq.acredaos.com/acre-daos-faqs/" target="_blank">
          FAQ
        </Links>
        {isAdmin && (
          <AdminLoginLink to="/admin">
            Admin Login <AdminLoginIcon />
          </AdminLoginLink>
        )}
        <Wallet />
        {!!name && showNavBtn && (
          <Link to={isMobile ? '/mobile-warning' : '/dashboard'}>
            <LaunchAppButton>Launch App ›</LaunchAppButton>
          </Link>
        )}
      </NavLinks>
      <>
        <MobileMenu isMobile={isMobile} open={open} setOpen={setOpen} />
        <BurgerButton open={open} setOpen={setOpen} />
      </>
    </Nav>
  );
}
