import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { injected } from '../../utils/connectors';

type MetamaskProviderProps = {
  children: any;
};

function MetamaskProvider({ children }: MetamaskProviderProps) {
  const {
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useWeb3React();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const connectedWallet = window.localStorage.getItem('connectedWallet');
    injected
      .isAuthorized()
      .then((isAuthorized) => {
        if (!connectedWallet) {
          return setLoaded(true);
        }
        setLoaded(true);
        if (isAuthorized && !networkActive && !networkError) {
          activateNetwork(injected);
        }
      })
      .catch(() => {
        setLoaded(true);
      });
  }, [activateNetwork, networkActive, networkError]);
  if (loaded) {
    return children;
  }
  return <></>;
}

export default MetamaskProvider;
