import { Logo } from '../../components/NavBar/Style';
import { LoaderPageContainer } from './Style';

export default function LoadingPage() {
  return (
    <LoaderPageContainer>
      <Logo src="/logo_mini_light.svg" alt="Logo" />
      <span>Loading...</span>
    </LoaderPageContainer>
  );
}
