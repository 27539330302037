import { lazy } from 'react';

export const LandingPage = lazy(() => import('../pages/LandingPage'));
export const About = lazy(() => import('../pages/AboutPage'));
export const Cookies = lazy(() => import('../pages/CookiesPage'));
export const TermsConditions = lazy(() => import('../pages/TermsConditions'));
export const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
export const Disclaimer = lazy(() => import('../pages/DisclaimerPage'));
export const Trademarks = lazy(() => import('../pages/TrademarksPage'));
export const QuickGuide = lazy(() => import('../pages/QuickGuide'));
export const SubmitForm = lazy(() => import('../pages/SubmitForm'));
export const Admin = lazy(() => import('../pages/Admin'));
export const Profile = lazy(() => import('../pages/Profile'));
export const Dashboard = lazy(() => import('../pages/Dashboard'));
export const MobileWarningPage = lazy(
  () => import('../pages/MobileWarningPage'),
);
