export const WALLETS = [
  {
    name: 'Metamask',
    image: '/Metamask.png',
    connectorName: 'injected',
  },
  // {
  //   name: 'Ledger',
  //   image: '/Ledger.png',
  //   connectorName: 'ledger',
  // },
  // {
  //   name: 'Coinbase Wallet',
  //   image: '/coinbase-wallet.png',
  //   connectorName: 'walletConnect',
  // },
  {
    name: 'Wallet Connect',
    image: 'Icon-Connections.png',
    connectorName: 'walletConnect',
  },
  {
    name: 'Trezor',
    image: '/Trezor-logo.png',
    connectorName: 'trezor',
  },
  {
    name: 'Fortmatic',
    image: '/Fortmatic.png',
    connectorName: 'fortmatic',
  },
];
