import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import LoadingPage from '../pages/LoadingPage';

export type AuthRouteProps = {
  isAuthenticated: boolean;
  loading?: boolean;
} & RouteProps;

function AuthRoute({
  isAuthenticated,
  loading,
  ...routeProps
}: AuthRouteProps) {
  if (loading) {
    return (
      <Route>
        <LoadingPage />
      </Route>
    );
  }
  if (isAuthenticated) {
    return <Route path={routeProps.path}>{routeProps.component}</Route>;
  }
  return <Redirect to="/" />;
}

export default AuthRoute;
