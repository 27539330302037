import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { accountShortener } from '../../utils/functions/globalfunc';
import WalletDropdown from '../WalletDropdown';
import { WalletConnectButton, AvatarIcon, WalletDataContent, P } from './Style';
import Modal from '../Modal';
import WalletConnectionModal from '../WalletConnectionModal';
import { useLocation } from 'react-router';
import { useUserContext } from '../../context/UserContext';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export default function Wallet() {
  const { name, imgUrl, approved } = useUserContext();
  const location = useLocation();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [attempted, setAttempted] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const { account, active } = useWeb3React<Web3Provider>();

  const loginWeb3 = async () => {
    if (isMobile) {
      return history.push('/mobile-warning');
    }
    setShow(true);
  };

  return (
    <>
      <Modal onClose={setShow} show={show}>
        <WalletConnectionModal onClose={setShow} />
      </Modal>
      {!!account && (
        <WalletDataContent>
          <WalletDropdown address={accountShortener(account as string)} />

          {(location.pathname.includes('/profile') ||
            location.pathname.includes('/messages') ||
            location.pathname.includes('/notifications') ||
            location.pathname.includes('/activity') ||
            location.pathname.includes('/search') ||
            location.pathname.includes('/dashboard')) && (
            <AvatarIcon>
              {!!imgUrl && approved ? (
                <img src={imgUrl} alt={name as string} />
              ) : (
                <AccountCircleIcon className="empty-avatar" />
              )}
            </AvatarIcon>
          )}
        </WalletDataContent>
      )}
      {!account && (
        <WalletConnectButton onClick={loginWeb3}>
          <P>Connect Wallet</P>
        </WalletConnectButton>
      )}
    </>
  );
}
