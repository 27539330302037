import { createGlobalStyle } from 'styled-components';

import TropicalGardenWoff from './TropicalGarden.woff';
import TropicalGardenOtf from './TropicalGarden.otf';
import TropicalGardenTtf from './TropicalGarden.ttf';

import JosefinSans from './JosefinSans.ttf';

import OverpassRegular from './Overpass-Regular.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: tropical garden;
        src: local('tropical garden'), local('TropicalGardenWoff'),
        url(${TropicalGardenWoff}) format('woff'),
        url(${TropicalGardenOtf}) format('opentype'),
        url(${TropicalGardenTtf}) format('truetype')
    }
    @font-face {
        font-family: 'Josefin Sans';
        src: local('Josefin Sans'),
        url(${JosefinSans}) format('truetype')
    }
    @font-face {
        font-family: 'Overpass Regular';
        src: local('Overpass Regular'),
        url(${OverpassRegular}) format('truetype')
    }
`;
