import GlobalStyle from './styles/globalStyles';
import GlobalFonts from './assets/fonts/fonts';
import DesktopLayout from './components/containers/DesktopLayout';
import Router from './router/router';
import UserProvider from './context/UserContext';

/* eslint-disable */
window.ethereum &&
  window.ethereum.on('chainChanged', () => {
    setTimeout(() => {
      window.location.reload();
    }, 1);
  });
window.ethereum &&
  window.ethereum.on('accountsChanged', () => {
    setTimeout(() => {
      window.location.reload();
    }, 1);
  });
/* eslint-enable */

function App() {
  return (
    <UserProvider>
      <DesktopLayout>
        <GlobalFonts />
        <GlobalStyle />
        <Router />
      </DesktopLayout>
    </UserProvider>
  );
}

export default App;
