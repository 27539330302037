import styled from 'styled-components';
import flex from '../../styles/mixins';
import { screenSizes } from '../../styles/screenSizes';

type FooterProps = {
  count: number;
};

export const Wrapper = styled.div`
  background: var(--color-blue-50);
  height: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  width: 100%;
  padding: 50px 80px;

  @media ${screenSizes.maxLaptop} {
    flex-direction: column;
    align-items: baseline;
    justify-content: baseline;
    height: fit-content;
    padding: 40px 10px;
  }
`;

export const WrapperMobile = styled.div`
  background: var(--color-white);
  ${flex('center', 'center', 'column')}
`;

export const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media ${screenSizes.maxLaptop} {
    height: fit-content;
    width: 100%;
    align-items: center;
    margin-bottom: 30px;
    /* order: 1; */
  }
`;

export const FooterRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;

  @media ${screenSizes.maxLaptop} {
    height: fit-content;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const FooterSocials = styled.div<FooterProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.count}, auto);
  grid-gap: 25px;

  @media ${screenSizes.maxLaptop} {
    margin-bottom: 20px;

    grid-gap: 40px;
  }
`;

export const FooterHelperLinks = styled.div<FooterProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.count}, auto);
  grid-gap: 20px;
`;

export const HelperLink = styled.div`
  font-family: var(--font-overpass);
  font-size: calc((13 / var(--base-font-size) * 1rem));
  color: var(--color-blue-300);
  cursor: pointer;

  &::focus {
    text-decoration: underline;
  }
`;

export const HelperMobileLInk = styled(HelperLink)`
  font-size: calc((16 / var(--base-font-size) * 1rem));
  margin-bottom: 15px;
`;

export const FooterLogo = styled.img`
  height: 60px;
  cursor: pointer;

  @media ${screenSizes.maxTablet} {
    display: none;
  }
`;

export const FooterLogoMobile = styled.img`
  height: 80px;
  cursor: pointer;
  margin-bottom: 20px;

  @media ${screenSizes.minTablet} {
    display: none;
  }
  @media ${screenSizes.maxMobileL} {
    margin: 0;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0;
  }
`;

export const FooterNavs = styled.div<FooterProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.count + 1}, auto);
  grid-gap: 40px;
  align-items: center;

  @media ${screenSizes.maxLaptop} {
    margin-bottom: 20px;
  }

  @media ${screenSizes.maxTablet} {
    grid-template-columns: repeat(${(props) => props.count}, auto);
  }
`;

export const FooterNavLink = styled.div`
  display: flex;
  margin-left: -100px;
  font-family: var(--font-overpass);
  font-size: calc((14 / var(--base-font-size) * 1rem));
  color: var(--color-blue-800);
  font-weight: bold;
  cursor: pointer;

  @media ${screenSizes.maxTablet} {
    margin-left: 0;
  }
`;

export const FooterFaqLink = styled.div`
  display: flex;
  margin-left: 20px;
  font-family: var(--font-overpass);
  font-size: calc((14 / var(--base-font-size) * 1rem));
  color: var(--color-blue-800);
  font-weight: bold;
  cursor: pointer;
`;

export const FooterForumLink = styled.div`
  display: flex;
  margin-left: 20px;
  font-family: var(--font-overpass);
  font-size: calc((14 / var(--base-font-size) * 1rem));
  color: var(--color-blue-800);
  font-weight: bold;
  cursor: pointer;
`;

export const FooterMobileLink = styled.div`
  display: flex;
  font-family: var(--font-overpass);
  font-size: calc((16 / var(--base-font-size) * 1rem));
  color: var(--color-blue-800);
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const FooterCopyright = styled.div<{ textPosition?: string }>`
  text-align: ${(props) => props.textPosition};
  font-family: var(--font-overpass);
  font-size: calc((13 / var(--base-font-size) * 1rem));
  color: var(--color-blue-300);
  width: fit-content;
`;

export const Separator = styled.div`
  width: 90%;
  height: 0.5px;
  margin-bottom: 15px;
  background: var(--color-blue-200);
`;
