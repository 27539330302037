import { useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router';
import { footer } from '../../constants';
import IconButton from '../IconButton';
import {
  FooterCopyright,
  FooterHelperLinks,
  FooterLeft,
  FooterLogo,
  FooterLogoMobile,
  FooterNavLink,
  FooterNavs,
  FooterRight,
  FooterSocials,
  HelperLink,
  Wrapper,
  FooterFaqLink,
  FooterForumLink,
  WrapperMobile,
  FooterMobileLink,
  HelperMobileLInk,
  Separator,
} from './Style';

export default function Footer() {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <WrapperMobile>
        <FooterLogoMobile
          src="/logo_dark.svg"
          onClick={() => {
            history.push('/');
          }}
        />

        <FooterMobileLink
          onClick={() => {
            history.push('/about');
          }}
        >
          About
        </FooterMobileLink>

        <FooterMobileLink
          onClick={() => {
            window.open('https://forum.acredaos.com', '_blank');
          }}
        >
          Forum
        </FooterMobileLink>

        <FooterMobileLink
          onClick={() => {
            window.open('https://faq.acredaos.com/acre-daos-faqs/', '_blank');
          }}
        >
          FAQ
        </FooterMobileLink>

        <Separator />

        {footer.helperLinksMobile.map((link) => {
          return (
            <HelperMobileLInk
              key={link.id + link.title}
              onClick={() => history.push(link.link)}
            >
              {link.title}
            </HelperMobileLInk>
          );
        })}

        <FooterSocials count={footer.socials.length}>
          {footer.socials.map((social) => {
            return (
              <IconButton
                key={social.id + social.link}
                fill
                onClick={() => {
                  window.open(social.link, '_blank');
                }}
                icon={social.icon}
                round="full"
              />
            );
          })}
        </FooterSocials>

        <Separator />

        <FooterCopyright textPosition="center">
          {footer.copyrightMobile}
        </FooterCopyright>
      </WrapperMobile>
    );
  }

  return (
    <Wrapper>
      <FooterLeft>
        <FooterLogoMobile
          src="/logo_dark.svg"
          onClick={() => {
            history.push('/');
          }}
        />
        <FooterNavs count={footer.navLinks.length}>
          <FooterLogo
            src="/logo_dark.svg"
            onClick={() => {
              history.push('/');
            }}
          />
          {footer.navLinks.map((link) => {
            return (
              <>
                <FooterNavLink
                  key={link.id + link.route}
                  onClick={() => {
                    history.push(link.route);
                  }}
                >
                  {link.title}
                  <FooterForumLink
                    onClick={() => {
                      window.open('https://forum.acredaos.com/', '_blank');
                    }}
                  >
                    Forum
                  </FooterForumLink>
                  <FooterFaqLink
                    onClick={() => {
                      window.open(
                        'https://faq.acredaos.com/acre-daos-faqs/',
                        '_blank',
                      );
                    }}
                  >
                    FAQ
                  </FooterFaqLink>
                </FooterNavLink>
              </>
            );
          })}
        </FooterNavs>
        <FooterCopyright>{footer.copyright}</FooterCopyright>
      </FooterLeft>
      <FooterRight>
        <FooterSocials count={footer.socials.length}>
          {footer.socials.map((social) => {
            return (
              <IconButton
                key={social.id + social.link}
                fill
                onClick={() => {
                  window.open(social.link, '_blank');
                }}
                icon={social.icon}
                round="full"
              />
            );
          })}
        </FooterSocials>
        <FooterHelperLinks count={footer.helperLinks.length}>
          {footer.helperLinks.map((link) => {
            return (
              <HelperLink
                key={link.id + link.title}
                onClick={() => history.push(link.link)}
              >
                {link.title}
              </HelperLink>
            );
          })}
        </FooterHelperLinks>
      </FooterRight>
    </Wrapper>
  );
}
