import { H1, Wrapper, MessageBox } from './Style';

interface WalletConnectionModalProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function WrongNetwork({ onClose }: WalletConnectionModalProps) {
  return (
    <Wrapper>
      <H1>Wrong Network</H1>
      <MessageBox>
        Please connect to the appropiate Ethereum Network (Mainnet).
      </MessageBox>
    </Wrapper>
  );
}
