import { useClickOutside } from '../../hooks/useClickOutside';
import { Background, Container, Wrapper } from './Style';

interface ModalProps {
  show: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}

export default function Modal({ show, children, onClose }: ModalProps) {
  const { innerRef } = useClickOutside(() => onClose(false));

  return (
    <Background show={show}>
      <Wrapper>
        <Container ref={innerRef}>
          {children ||
            'Oops, It looks like there is a problem with the display!'}
        </Container>
      </Wrapper>
    </Background>
  );
}
