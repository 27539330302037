import { useState, useEffect } from 'react';
import { Title, Icon, WalletWrapper } from './Style';
import {
  injected,
  network,
  walletConnect,
  walletLink,
  ledger,
  portis,
  fortmatic,
  trezor,
} from '../../utils/connectors';
import { useWeb3React } from '@web3-react/core';

interface WalletButtonProps {
  name: string;
  imgLink: string;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  connectorName: string;
}

enum ConnectorNames {
  Injected = 'injected',
  Network = 'network',
  WalletConnect = 'walletConnect',
  WalletLink = 'walletLink',
  Ledger = 'ledger',
  Portis = 'portis',
  Fortmatic = 'fortmatic',
  Trezor = 'trezor',
}

const connectorsByName: { [key in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.Network]: network,
  [ConnectorNames.WalletConnect]: walletConnect,
  [ConnectorNames.WalletLink]: walletLink,
  [ConnectorNames.Ledger]: ledger,
  [ConnectorNames.Portis]: portis,
  [ConnectorNames.Fortmatic]: fortmatic,
  [ConnectorNames.Trezor]: trezor,
};

export default function WalletButton({
  name,
  imgLink,
  onClose,
  connectorName,
}: WalletButtonProps) {
  const [activatingConnector, setActivatingConnector] = useState<any>();
  const { connector, activate, account: walletAddress } = useWeb3React();
  const currentConnector = connectorsByName[name as ConnectorNames];
  const activating = currentConnector === activatingConnector;
  const connected = currentConnector === connector;

  // const triedEager = useEagerConnect();
  // const useInactiveListener(!triedEager || !!activatingConnector);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const handleClick = () => {
    try {
      setActivatingConnector(currentConnector);
      activate(connectorsByName[connectorName as ConnectorNames]);
      onClose(false);
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <WalletWrapper onClick={handleClick}>
      <Icon src={imgLink} alt={imgLink} />
      <Title>{name}</Title>
    </WalletWrapper>
  );
}
