export const faqQuestions = {
  questions: [
    {
      question: 'This is an example question?',
      answer:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna enim ad minim veniam, quis nostrud exercitation ullamcoaliqua.',
    },
    {
      question: 'This is another question?',
      answer:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna enim ad minim veniam, quis nostrud exercitation ullamcoaliqua.',
    },
    {
      question: 'This is another question',
      answer:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna enim ad minim veniam, quis nostrud exercitation ullamcoaliqua.',
    },
  ],
};
