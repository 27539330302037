import { round } from 'lodash';
import { Wrapper } from './Style';

interface IconButtonProps {
  onClick?: React.MouseEventHandler;
  text?: string;
  icon?: string;
  fill: boolean;
  round: 'full' | string;
}

export default function ({
  onClick,
  text,
  icon,
  fill,
  round,
}: IconButtonProps) {
  return (
    <Wrapper
      fill={fill ? 1 : 0}
      round={round}
      hasText={text !== null && text !== undefined && text !== ''}
      onClick={onClick}
    >
      {icon && <i className={icon} />}
      {text}
    </Wrapper>
  );
}
