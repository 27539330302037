import styled from 'styled-components';
import { header3, bodyParagraph } from '../../styles/sharedStyles';

export const Wrapper = styled.div`
  width: auto;
  height: auto;
  background-color: var(--color-white);
  box-shadow: -16px 24px 24px rgba(84, 124, 203, 0.3);
  padding: 50px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const H1 = styled.span`
  ${header3};
  color: var(--color-blue-800);
  font-size: 28px;
`;

export const MessageBox = styled.span`
  ${bodyParagraph};
  color: var(--color-blue-800);
  margin-top: 5px;
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;
