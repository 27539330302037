const flex = (
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
  direction = 'row',
): string => `
    display: flex;
    align-items: ${alignItems};
    justify-content: ${justifyContent};
    flex-direction: ${direction};
  `;

export default flex;
