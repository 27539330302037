import { css } from 'styled-components';

export const header1 = css`
  font-family: var(--font-tropical);
  font-size: calc((60 / var(--base-font-size) * 1rem));
  line-height: calc(75.66 / var(--base-font-size) * 1rem);
  font-weight: 400;
  color: var(--color-white);
  text-shadow: -30px 24px 64px 0 var(--color-blue-500);
`;

export const header2 = css`
  font-family: var(--font-tropical);
  font-size: calc((48 / var(--base-font-size) * 1rem));
  line-height: calc(60 / var(--base-font-size) * 1rem);
  font-weight: 400;
  text-align: center;
  color: var(--color-white);
`;

export const header3 = css`
  font-family: var(--font-overpass);
  font-size: calc((40 / var(--base-font-size) * 1rem));
  line-height: calc(50 / var(--base-font-size) * 1rem);
  font-weight: 800;
  color: var(--color-white);
`;

export const header4 = css`
  font-family: var(--font-overpass);
  font-size: calc((24 / var(--base-font-size) * 1rem));
  font-weight: 800;
  color: var(--color-white);
`;

export const header5 = css`
  font-family: var(--font-overpass);
  font-size: calc((18 / var(--base-font-size) * 1rem));
  line-height: calc((22.5 / var(--base-font-size) * 1rem));
  font-weight: 800;
  color: var(--color-white);
`;

export const header6 = css`
  font-family: var(--font-overpass);
  font-size: calc((14 / var(--base-font-size) * 1rem));
  line-height: calc((17.5 / var(--base-font-size) * 1rem));
  font-weight: 800;
  color: var(--color-white);
`;

export const navLink = css`
  font-family: var(--font-overpass);
  font-size: calc((18 / var(--base-font-size) * 1rem));
  line-height: calc((22.32 / var(--base-font-size) * 1rem));
  color: var(--color-blue-600);
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
`;

export const sectionOverpassSmall = css`
  font-family: var(--font-overpass);
  font-size: calc((14 / var(--base-font-size) * 1rem));
  font-weight: 400;
  line-height: calc((23.1 / var(--base-font-size) * 1rem));
  letter-spacing: -0.03em;
  color: var(--color-green-500);
  margin-top: 0;
  padding-top: 0;
`;

export const bodyParagraph = css`
  font-family: var(--font-overpass);
  font-size: calc((16 / var(--base-font-size) * 1rem));
  line-height: calc(26.4 / var(--base-font-size) * 1rem);
  font-weight: 400;
  color: var(--color-white);

  .small {
    font-size: calc((15 / var(--base-font-size) * 1rem));
    line-height: calc(15 / var(--base-font-size) * 1rem);
  }

  .bold {
    font-weight: 700;
  }
`;

export const subtitle1 = css`
  font-family: var(--font-overpass);
  font-size: calc((24 / var(--base-font-size) * 1rem));
  line-height: calc(30 / var(--base-font-size) * 1rem);
  font-weight: 400;
  color: var(--color-white);
`;

export const subtitle2 = css`
  font-family: var(--font-overpass);
  font-size: calc((20 / var(--base-font-size) * 1rem));
  line-height: calc(25 / var(--base-font-size) * 1rem);
  font-weight: 400;
  color: var(--color-white);
`;

export const textShadow = css`
  text-shadow: -30px 24px 64px var(--color-blue-500);
  color: var(--color-white);
`;

export const shadow = css`
  box-shadow: 3.6365px 8.23261px 42px rgba(82, 98, 104, 0.07),
    2.35699px 5.33595px 24.5972px rgba(82, 98, 104, 0.0531481),
    1.40072px 3.17108px 13.3778px rgba(82, 98, 104, 0.0425185),
    0.727299px 1.64652px 6.825px rgba(82, 98, 104, 0.035),
    0.296307px 0.670805px 3.42222px rgba(82, 98, 104, 0.0274815),
    0.0673425px 0.152456px 1.65278px rgba(82, 98, 104, 0.0168519);
`;

export const noFocus = css`
  :hover {
    border-bottom: none;
    outline: 0;
    box-shadow: none;
  }
  :active {
    border-bottom: none;
    outline: 0;
    box-shadow: none;
  }
`;
