import styled from 'styled-components';
import { screenSizes } from '../../styles/screenSizes';
import { bodyParagraph } from '../../styles/sharedStyles';

interface WalletButtonProps {
  ImageSource?: string;
}

export const WalletWrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--color-blue-800);
  border-radius: 16px;
  background-color: var(--color-blue-50);
  padding: 28px;
  margin: 0 10px;
  max-width: 300px;
  max-height: 100px;
  cursor: pointer;
  width: 100%;
  transition: opacity 0.25s ease-in-out;
  &:hover {
    opacity: 0.6;
  }
  @media ${screenSizes.maxLaptop} {
    margin: 10px auto;
    padding: 18px;
  }
`;
export const Title = styled.div`
  ${bodyParagraph};
  color: var(--color-blue-800);
  font-family: var(--font-overpass);
  font-weight: bold;
  margin-left: 8px;
  @media ${screenSizes.maxMobileL} {
    font-size: 13px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 13px;
  }
`;
export const Icon = styled.img<WalletButtonProps>`
  max-width: 64px;
  max-height: 60px;
`;
