import moment from 'moment';
import { encode } from 'punycode';

export const getMintedMin = (minted: Date): number => {
  let curDate = new Date();
  let difbyMin = Math.floor((curDate.getTime() - minted.getTime()) / 1000 / 60);
  return difbyMin;
};

export const getDate = (minted: Date): string => {
  return minted.getMonth() + '.' + minted.getDay() + '.' + minted.getFullYear();
};

export const getStringTimeFromNow = (time: any) =>
  moment(parseInt(time)).fromNow();

export const accountShortener = (account: string): string => {
  if (!account) return '';
  return `${account.substring(0, 6)}...${account.substring(
    account.length - 4,
  )}`;
};

export const amountFormatter = (amount: number): string => {
  const formattedAmount: string = amount
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return formattedAmount;
};

export const discordUsernameCheck = (discordUser: string): boolean => {
  return discordUser.slice(-5, -4) === '#';
};

export const twitterUsernameCheck = (twitterUser: string): boolean => {
  if (twitterUser[0] === '@') {
    return true;
  }
  return twitterUser.slice(0, 19) === 'https://twitter.com';
};

export interface SocialLinksProps {
  twitter: string | undefined;
  instagram: string | undefined;
  github: string | undefined;
  linkedIn: string | undefined;
  personalBlog: string | undefined;
}

export const formatSocialLinks = (
  links: SocialLinksProps,
): SocialLinksProps => {
  if (links.twitter) {
    if (
      links.twitter.indexOf('http://') == -1 &&
      links.twitter.indexOf('https://') == -1
    ) {
      links.twitter = `https://twitter.com/${links.twitter}`;
    }
  }
  if (links.instagram) {
    if (
      links.instagram.indexOf('http://') == -1 &&
      links.instagram.indexOf('https://') == -1
    ) {
      links.instagram = `https://instagram.com/${links.instagram}`;
    }
  }
  if (links.github) {
    if (
      links.github.indexOf('http://') == -1 &&
      links.github.indexOf('https://') == -1
    ) {
      links.github = `https://github.com/${links.github}`;
    }
  }
  if (links.linkedIn) {
    if (
      links.linkedIn.indexOf('http://') == -1 &&
      links.linkedIn.indexOf('https://') == -1
    ) {
      links.linkedIn = `https://linkedin.com/${links.linkedIn}`;
    }
  }
  if (links.personalBlog) {
    if (
      links.personalBlog.indexOf('http://') == -1 &&
      links.personalBlog.indexOf('https://') == -1
    ) {
      links.personalBlog = `https://${links.personalBlog}`;
    }
  }

  return links;
};
