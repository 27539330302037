export const landingPage = {
  headline: {
    title: 'Impact Investing Re-imagined.',
    subtitle1: 'Support your favorite causes.',
    subtitle2: 'Grow your meta-garden.',
    button: 'Join Us Today',
    buttonRegistered: 'Launch App',
  },
  socialImpact: {
    title: 'A new model for social impact',
    subtitle: `We believe in breaking the barriers that limit impact investing to
        the ultra-wealthy. We are an autonomous community of investors,
        advocates, and intellects.`,
    contents: [
      {
        id: 0,
        title: 'Find your community',
        description:
          'We believe in breaking the barriers that limit impact investing to the ultra-wealthy.',
        imageSource: '/tree_landing.png',
      },
      {
        id: 1,
        title: 'Plant your seeds',
        description:
          'We believe in breaking the barriers that limit impact investing to the ultra-wealthy.',
        imageSource: '/tree_landing.png',
      },
      {
        id: 2,
        title: 'Grow your garden',
        description:
          'We believe in breaking the barriers that limit impact investing to the ultra-wealthy.',
        imageSource: '/tree_landing.png',
      },
    ],
  },
  story: {
    title: 'The ACRE Dao Story',
    subtitles: [
      'Rooted in impact.',
      'Grown by community.',
      'Bloomed into web3.',
    ],
  },
  roadmap: {
    title: 'Our Roadmap',
    subtitle:
      'ACRE  (A C R E), is breaking the barriers that limit impact investing. One step at a time.',
    pageTitles: ['Seed', 'Sprout', 'Flower', 'Harvest'],
  },
  team: {
    title: 'Meet the team',
    subtitle:
      'We believe in breaking the barriers that limit impact investing to the ultra-wealthy.',
    members: [
      {
        id: 0,
        name: 'James Werwick',
        title: 'Senior Director',
        company: 'RegenAG',
        imageSource: '/BarnImage.jpeg',
        link: 'https://www.youtube.com/watch?v=Vmb1tqYqyII',
      },
      {
        id: 1,
        name: 'James Werwick',
        title: 'Senior Director',
        company: 'RegenAG',
        imageSource: '/BarnImage.jpeg',
        link: 'https://www.youtube.com/watch?v=Vmb1tqYqyII',
      },
      {
        id: 2,
        name: 'James Werwick',
        title: 'Senior Director',
        company: 'RegenAG',
        imageSource: '/BarnImage.jpeg',
        link: 'https://www.youtube.com/watch?v=Vmb1tqYqyII',
      },
      {
        id: 3,
        name: 'James Werwick',
        title: 'Senior Director',
        company: 'RegenAG',
        imageSource: '/BarnImage.jpeg',
        link: 'https://www.youtube.com/watch?v=Vmb1tqYqyII',
      },
    ],
  },
};
