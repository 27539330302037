import { StyledBurger } from './Style';

interface BurgerButtonProps {
  open: boolean;
  setOpen: Function;
}

const BurgerButton = ({ open, setOpen }: BurgerButtonProps) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default BurgerButton;
