export const NFT_EXAMPLE = 'ACRE Tree Blue';

export const quickStartGuide = {
  stepOne: {
    title: 'We are a community taking a new approach to impact investing.',
  },
  stepTwo: {
    title: '1: Submit your application to join.',
    subtitle: (
      <span>
        New Members receive an airdrop of 1,000{' '}
        <b>
          ACRE
          <br />
          Impact
        </b>{' '}
        tokens and full access to the community portal.
      </span>
    ),
    subtitleMobile: (
      <span>
        New Members receive an airdrop of 1,000 <b>ACRE Impact</b> tokens and
        and full access to the community portal.
      </span>
    ),
    link1: `Don't have a wallet? Make one here`,
    link2: 'Learn more about our membership structure.',
  },
  stepThree: {
    title: '2: Select your impact areas of interest.',
    subtitle: ``,
  },
  stepFour: {
    title: '3: Join our community!',
    titleMobile: <>3: Join our community!</>,
    subtitle: (
      <span>
        Find like-minded innovators and workshop new ideas for Web3 impact
        <br />
        investing opportunities.
      </span>
    ),
    squareInitiative: {
      title: 'Regen Carbon Reduction',
      likeNumber: '98',
      name: 'Post by James',
    },
  },
};
