import { Link } from 'react-router-dom';
import styled from 'styled-components';
import flex from '../../styles/mixins';
import { screenSizes } from '../../styles/screenSizes';
import { navLink } from '../../styles/sharedStyles';

type MenuProps = {
  open?: boolean;
  transparent?: boolean;
};

export const Nav = styled.nav<MenuProps>`
  background: ${(props) =>
    props.transparent ? 'transparent' : 'var(--color-blue-100)'};
  height: 91px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.transparent ? '0.2rem 90px' : '0 20px')};
  padding-top: ${(props) => (props.transparent ? '91px' : '0px')};
  position: sticky;
  z-index: 12;
  width: 100%;
  /* margin: 3em auto 0 auto; */

  @media ${screenSizes.maxMobileL} {
    padding: 20px 20px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding: 20px 20px;
  }
`;

const navBarLinksSize = (
  registered?: boolean,
  light?: boolean,
  admin?: boolean,
): string => {
  if (!registered) return '380px';
  if (admin) return '620px';
  if (light) return '480px';
  return '380px';
};

export const NavLinks = styled.div<{
  isAdmin?: boolean;
  lightNavbar?: boolean;
  registered?: boolean;
}>`
  ${flex('center', 'space-around')};
  width: ${({ registered, lightNavbar, isAdmin }) =>
    navBarLinksSize(registered, lightNavbar, isAdmin)};

  @media ${screenSizes.maxTablet} {
    display: none;
    width: auto;
  }
`;

export const MobileLinks = styled.div`
  ${flex('center', 'center', 'column')};

  > a {
    margin-bottom: 25px;
  }
  @media ${screenSizes.maxTablet} {
    display: flex;
  }
`;

export const Logo = styled.img`
  height: 4.75em;
  width: 7.8375em;
  transition: opacity 0.25s ease-in-out;
  &:hover {
    opacity: 0.6;
  }
`;

export const StyledBurger = styled.button<MenuProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1006;

  position: ${(props) => (props.open ? 'fixed' : 'relative')};
  right: ${(props) => (props.open ? '90px' : 'auto')};

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: var(--color-white);
    /* background: var(--color-blue-800); */
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }

  @media ${screenSizes.minTablet} {
    display: none;
  }

  @media ${screenSizes.maxMobileL} {
    right: ${(props) => (props.open ? '20px' : 'auto')};
  }
  @media (min-width: 320px) and (max-width: 480px) {
    right: ${(props) => (props.open ? '20px' : 'auto')};
  }
`;

export const StyledMenu = styled.nav<MenuProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  /* background: var(--color-blue-300); */
  background: var(--color-blue-radial-200-400);

  padding: 3rem;

  position: fixed;
  top: 0;
  right: -100vw;
  z-index: 1005;

  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(-100vw)' : 'translateX(0vw)')};

  @media ${screenSizes.minTablet} {
    display: none;
  }

  > a {
    .nav-link {
      margin-bottom: 32px;
      font-size: calc((32 / var(--base-font-size) * 1rem));
      line-height: calc((39.68 / var(--base-font-size) * 1rem));
    }
  }

  > div {
    font-size: calc((32 / var(--base-font-size) * 1rem));
    line-height: calc((39.68 / var(--base-font-size) * 1rem));
  }
`;

export const LaunchAppButton = styled.button<{ marginBottom?: string }>`
  ${flex('center', 'center')};
  border: none;
  width: 136px;
  height: 36px;
  color: var(--color-white);
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: ${(props) => props.marginBottom || '0px'};
  font-family: var(--font-overpass);
  background: var(--color-blue-800);
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
`;

export const AdminLoginLink = styled(Link)`
  ${flex('center', 'center')};
  font-family: var(--font-overpass);
  font-size: calc((18 / var(--base-font-size) * 1rem));
  line-height: calc((22.32 / var(--base-font-size) * 1rem));
  color: var(--color-blue-600);
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  svg {
    width: 18px;
    margin-left: 10px;
  }
`;

export const Links = styled.a`
  ${navLink};
`;
