import styled from 'styled-components';
import flex from '../../styles/mixins';
import { navLink } from '../../styles/sharedStyles';

export const WalletConnectButton = styled.div`
  font-family: var(--font-overpass);
  font-size: calc((18 / var(--base-font-size) * 1rem));
  line-height: calc((22.32 / var(--base-font-size) * 1rem));
  color: var(--color-blue-800);
  font-weight: 900;
  cursor: pointer;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
  transition: opacity 0.25s ease-in-out;
  &:hover {
    opacity: 0.6;
  }
`;

export const P = styled.p`
  ${navLink};
`;

export const AvatarIcon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid var(--color-blue-50);
  margin-left: 10px;
  img {
    border-radius: 50%;
  }
  .empty-avatar {
    color: var(--color-blue-800);
    width: 100%;
    height: 100%;
  }
`;

export const WalletDataContent = styled.div`
  ${flex('center', 'center')}
`;
