import { Web3Provider } from '@ethersproject/providers';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import IERC20DetailedABI from '../../ethereum/abis/IERC20Detailed.json';

export async function getACREBalance(
  reserve: string,
  provider: Web3Provider,
  account: string,
): Promise<string> {
  const token = new ethers.Contract(reserve, IERC20DetailedABI, provider);
  const tokenBalance = await token.balanceOf(account);
  const decimals = await token.decimals();
  const totalBalance = await convertToCurrencyUnits(decimals, tokenBalance);
  return totalBalance;
}

export async function convertToCurrencyUnits(
  tokenDecimals: string,
  amount: string,
): Promise<string> {
  const decimals = new BigNumber(tokenDecimals);
  const currencyUnit = new BigNumber(10).pow(decimals);
  const amountInCurrencyUnits = Number(amount) / Number(currencyUnit);
  return amountInCurrencyUnits.toFixed();
}
