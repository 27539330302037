import React, { createContext, useContext, useState } from 'react';

export type StepperContextValues = {
  step: number;
  type: StepperTypes | null;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  completedSteps: Array<number>;
  setCompletedSteps: React.Dispatch<React.SetStateAction<Array<number>>>;
  highestStep: number;
  setHighestStep: React.Dispatch<React.SetStateAction<number>>;
  setType: React.Dispatch<React.SetStateAction<StepperTypes | null>>;
};

export const StepperDefaultValues: StepperContextValues = {
  step: 1,
  setStep: () => null,
  type: null,
  completedSteps: [],
  setCompletedSteps: () => null,
  highestStep: 0,
  setHighestStep: () => null,
  setType: () => null,
};

export enum StepperTypes {
  QUICK_GUIDE = 'QUICK_GUIDE',
  SUBMIT_FORM = 'SUBMIT_FORM',
}

export const StepperContext =
  createContext<StepperContextValues>(StepperDefaultValues);

export const useStepperContext = () => useContext(StepperContext);

interface StepperProviderProps {
  children: React.ReactNode;
}

const StepperProvider = ({ children }: StepperProviderProps) => {
  const [step, setStep] = useState<number>(1);
  const [type, setType] = useState<StepperTypes | null>(null);
  const [completedSteps, setCompletedSteps] = useState<Array<number>>([]);
  const [highestStep, setHighestStep] = useState<number>(1);

  return (
    <StepperContext.Provider
      value={{
        step,
        completedSteps,
        highestStep,
        setStep,
        setCompletedSteps,
        setHighestStep,
        setType,
        type,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};

export default StepperProvider;
