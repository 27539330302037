import styled from 'styled-components';
import flex from '../../styles/mixins';

export const LoaderPageContainer = styled.div`
  ${flex('center', 'center', 'column')};
  height: 100vh;
  width: 100%;
  span {
    font-family: var(--font-overpass);
    font-weight: bold;
    color: var(--color-white);
  }
`;
