import { useWeb3React } from '@web3-react/core';
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { getACREBalance } from '../utils/web3/erc20';
import {
  MAINNET_ACRE_TOKEN,
  TESTNET_ACRE_TOKEN,
} from '../constants/tokenAddresses';
import { useLazyQuery } from '@apollo/client';
import { GET_INDIVIDUAL_APPLICANT } from '../utils/graphQL/queries';

export type UserContextValues = {
  id: string;
  setId: React.Dispatch<React.SetStateAction<string>>;
  createdAt: string;
  setCreatedAt: React.Dispatch<React.SetStateAction<string>>;
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  name: string | null;
  setName: React.Dispatch<React.SetStateAction<string | null>>;
  imgUrl: string;
  setImgUrl: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  discord: string;
  setDiscord: React.Dispatch<React.SetStateAction<string>>;
  linkedIn: string;
  setLinkedIn: React.Dispatch<React.SetStateAction<string>>;
  personalBlog: string;
  setPersonalBlog: React.Dispatch<React.SetStateAction<string>>;
  other: string;
  setOther: React.Dispatch<React.SetStateAction<string>>;
  interests: string[];
  setInterests: React.Dispatch<React.SetStateAction<string[]>>;
  investor: boolean;
  setInvestor: React.Dispatch<React.SetStateAction<boolean>>;
  web3Enthusiast: boolean;
  setWeb3Enthusiast: React.Dispatch<React.SetStateAction<boolean>>;
  researcher: boolean;
  setResearcher: React.Dispatch<React.SetStateAction<boolean>>;
  projectLeader: boolean;
  setProjectLeader: React.Dispatch<React.SetStateAction<boolean>>;
  identifyOther: boolean;
  setIdentifyOther: React.Dispatch<React.SetStateAction<boolean>>;
  approved: boolean;
  setApproved: React.Dispatch<React.SetStateAction<boolean>>;
  isAdmin: boolean;
  setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  role: string;
  setRole: React.Dispatch<React.SetStateAction<string>>;
  twitter: string;
  setTwitter: React.Dispatch<React.SetStateAction<string>>;
  github: string;
  setGithub: React.Dispatch<React.SetStateAction<string>>;
  organizationName: string;
  setOrganizationName: React.Dispatch<React.SetStateAction<string>>;
  organizationWebsite: string;
  setOrganizationWebsite: React.Dispatch<React.SetStateAction<string>>;
  about: string;
  setAbout: React.Dispatch<React.SetStateAction<string>>;
  acreBalance: string;
  setAcreBalance: React.Dispatch<React.SetStateAction<string>>;
  instagram: string;
  setInstagram: React.Dispatch<React.SetStateAction<string>>;
  applicantRegistered: boolean;
  loadingUserData: boolean;
  setLoadingUserData: React.Dispatch<React.SetStateAction<boolean>>;
  setApplicantRegistered: React.Dispatch<React.SetStateAction<boolean>>;
  setDiscourseUsername: React.Dispatch<React.SetStateAction<string>>;
  discourseUsername: string;
};

export const UserContextDefaultValues: UserContextValues = {
  id: '',
  setId: () => null,
  createdAt: '',
  setCreatedAt: () => null,
  address: '',
  setAddress: () => null,
  name: '',
  setName: () => null,
  imgUrl: '',
  setImgUrl: () => null,
  email: '',
  setEmail: () => null,
  phone: '',
  setPhone: () => null,
  discord: '',
  setDiscord: () => null,
  linkedIn: '',
  setLinkedIn: () => null,
  other: '',
  setOther: () => null,
  interests: [''],
  setInterests: () => null,
  investor: false,
  setInvestor: () => null,
  web3Enthusiast: false,
  setWeb3Enthusiast: () => null,
  researcher: false,
  setResearcher: () => null,
  projectLeader: false,
  setProjectLeader: () => null,
  identifyOther: false,
  setIdentifyOther: () => null,
  isAdmin: false,
  setIsAdmin: () => null,
  approved: false,
  setApproved: () => null,
  twitter: '',
  setTwitter: () => null,
  github: '',
  setGithub: () => null,
  organizationName: '',
  setOrganizationName: () => null,
  organizationWebsite: '',
  setOrganizationWebsite: () => null,
  about: '',
  setAbout: () => null,
  role: '',
  setRole: () => null,
  acreBalance: '0.00',
  setAcreBalance: () => null,
  instagram: '',
  personalBlog: '',
  setInstagram: () => null,
  setPersonalBlog: () => null,
  setLoadingUserData: () => null,
  setApplicantRegistered: () => null,
  setDiscourseUsername: () => null,
  discourseUsername: '',
  applicantRegistered: false,
  loadingUserData: false,
};

export const UserContext = createContext<UserContextValues>(
  UserContextDefaultValues,
);

export const useUserContext = () => useContext(UserContext);

interface UserProviderProps {
  children: React.ReactNode;
}

const UserProvider = ({ children }: UserProviderProps) => {
  const [loadingUserData, setLoadingUserData] = useState<boolean>(true);
  const [id, setId] = useState<string>('');
  const [createdAt, setCreatedAt] = useState<string>('');
  const [organizationName, setOrganizationName] = useState<string>('');
  const [organizationWebsite, setOrganizationWebsite] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [name, setName] = useState<string | null>('');
  const [imgUrl, setImgUrl] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [discord, setDiscord] = useState<string>('');
  const [linkedIn, setLinkedIn] = useState<string>('');
  const [twitter, setTwitter] = useState<string>('');
  const [github, setGithub] = useState<string>('');
  const [instagram, setInstagram] = useState<string>('');
  const [personalBlog, setPersonalBlog] = useState<string>('');
  const [other, setOther] = useState<string>('');
  const [interests, setInterests] = useState<string[]>(['']);
  const [investor, setInvestor] = useState<boolean>(false);
  const [web3Enthusiast, setWeb3Enthusiast] = useState<boolean>(false);
  const [researcher, setResearcher] = useState<boolean>(false);
  const [projectLeader, setProjectLeader] = useState<boolean>(false);
  const [identifyOther, setIdentifyOther] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [role, setRole] = useState<string>('');
  const [approved, setApproved] = useState<boolean>(false);
  const [about, setAbout] = useState<string>('');
  const [acreBalance, setAcreBalance] = useState<string>('0.00');
  const [discourseUsername, setDiscourseUsername] = useState<string>('');

  const [applicantRegistered, setApplicantRegistered] =
    useState<boolean>(false);

  const { account: walletAddress, library } = useWeb3React();

  const [getApplicant, { loading, error, data }] = useLazyQuery(
    GET_INDIVIDUAL_APPLICANT,
  );

  const getAcountAcreBalance = useCallback(async () => {
    if (!walletAddress) return;

    const acreBalance = await getACREBalance(
      MAINNET_ACRE_TOKEN,
      library,
      walletAddress,
    );

    setAcreBalance(acreBalance);
  }, [walletAddress, library]);

  const setVariables = useCallback((): void => {
    if (data?.applicant === undefined) return;
    if (data?.applicant === null) {
      setName(null);
      setLoadingUserData(false);
      return;
    }
    setOrganizationName(data.applicant.organizationName);
    setOrganizationWebsite(data.applicant.organizationWebsite);
    setTwitter(data.applicant.twitter);
    setGithub(data.applicant.github);
    setAbout(data.applicant.about);
    setApproved(data.applicant.approved);
    setIsAdmin(data.applicant.isAdmin);
    setRole(data.applicant.role);
    setIdentifyOther(data.applicant.identifyOther);
    setProjectLeader(data.applicant.projectLeader);
    setResearcher(data.applicant.researcher);
    setWeb3Enthusiast(data.applicant.web3Enthusiast);
    setInvestor(data.applicant.investor);
    setInterests(data.applicant.interests);
    setOther(data.applicant.other);
    setLinkedIn(data.applicant.linkedIn);
    setInstagram(data.applicant.instagram);
    setDiscord(data.applicant.discord);
    setPhone(data.applicant.phone);
    setEmail(data.applicant.email);
    setImgUrl(data.applicant.imgUrl);
    setName(data.applicant.name);
    setAddress(data.applicant.address);
    setCreatedAt(data.applicant.createdAt);
    setId(data.applicant.id);
    setDiscourseUsername(data.applicant.discourseUsername);
  }, [data]);

  useEffect(() => {
    setVariables();
  }, [data]);

  useEffect(() => {
    if (!walletAddress) {
      setLoadingUserData(false);
      return;
    }

    getAcountAcreBalance();
    getApplicant({
      variables: { address: walletAddress },
    });
  }, [walletAddress]);

  return (
    <UserContext.Provider
      value={{
        id,
        createdAt,
        address,
        name,
        imgUrl,
        email,
        phone,
        discord,
        linkedIn,
        other,
        interests,
        investor,
        web3Enthusiast,
        researcher,
        projectLeader,
        identifyOther,
        isAdmin,
        approved,
        role,
        organizationName,
        organizationWebsite,
        twitter,
        github,
        about,
        acreBalance,
        instagram,
        personalBlog,
        applicantRegistered,
        loadingUserData,
        discourseUsername,
        setLoadingUserData,
        setAcreBalance,
        setAbout,
        setGithub,
        setTwitter,
        setOrganizationWebsite,
        setOrganizationName,
        setRole,
        setApproved,
        setIsAdmin,
        setIdentifyOther,
        setProjectLeader,
        setResearcher,
        setWeb3Enthusiast,
        setInvestor,
        setInterests,
        setOther,
        setLinkedIn,
        setDiscord,
        setPhone,
        setEmail,
        setImgUrl,
        setName,
        setAddress,
        setCreatedAt,
        setId,
        setInstagram,
        setPersonalBlog,
        setApplicantRegistered,
        setDiscourseUsername,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
