export const footer = {
  navLinks: [
    {
      id: 0,
      title: 'About',
      route: '/about',
    },
  ],
  socials: [
    {
      id: 0,
      icon: 'fab fa-twitter',
      link: 'https://twitter.com/acredaos',
    },
    { id: 1, icon: 'fab fa-instagram', link: 'https://instagram.com/acredaos' },
    { id: 2, icon: 'fab fa-discord', link: 'https://discord.gg/acredaos' },
  ],
  helperLinks: [
    {
      id: 0,
      title: 'We Use Cookies',
      link: '/cookies',
    },
    { id: 1, title: 'Disclaimer', link: '/disclaimer' },
    { id: 2, title: 'Trademarks', link: '/trademarks' },
    { id: 3, title: 'Privacy', link: '/privacy' },
    { id: 4, title: 'Terms & Conditions', link: '/terms' },
  ],
  helperLinksMobile: [
    { id: 1, title: 'Disclaimer', link: '/disclaimer' },
    { id: 2, title: 'Trademarks', link: '/trademarks' },
    {
      id: 0,
      title: 'We Use Cookies',
      link: '/cookies',
    },
    { id: 4, title: 'Terms & Conditions', link: '/terms' },
    { id: 3, title: 'Privacy', link: '/privacy' },
  ],
  copyright: `${new Date().getFullYear()} © Acre of America Onshore LP. All Rights Reserved.`,
  copyrightMobile: (
    <span>
      {new Date().getFullYear()} © <br /> Acre of America Onshore LP <br /> All
      Rights Reserved.
    </span>
  ),
};
