import { useMediaQuery, useTheme } from '@material-ui/core';
import { isNull } from 'lodash';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import StepperProvider from '../context/StepperContext';
import { useUserContext } from '../context/UserContext';
import AuthRoute from './authRoute';
import {
  About,
  Admin,
  Cookies,
  Disclaimer,
  LandingPage,
  PrivacyPolicy,
  Profile,
  QuickGuide,
  SubmitForm,
  TermsConditions,
  Trademarks,
  Dashboard,
  MobileWarningPage,
} from './routes';

export default function Router() {
  const {
    loadingUserData,
    isAdmin,
    approved,
    acreBalance,
    name,
    setLoadingUserData,
  } = useUserContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [approvedMember, setApprovedMember] = useState<boolean>(false);

  const getIsAuthenticated = useCallback(async () => {
    if (!approved || acreBalance === '0.00') {
      setApprovedMember(false);
      return;
    }
    setApprovedMember(true);
  }, [approved, acreBalance]);

  useEffect(() => {
    getIsAuthenticated();
  }, [approved, acreBalance]);

  useEffect(() => {
    if (!!name) {
      setLoadingUserData(false);
    }
  }, [name]);

  return (
    <Suspense fallback={<></>}>
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route path="/privacy">
          <ScrollToTop />
          <PrivacyPolicy />
        </Route>
        <Route path="/about">
          <ScrollToTop />
          <About />
        </Route>
        <Route path="/cookies">
          <ScrollToTop />
          <Cookies />
        </Route>
        <Route path="/terms">
          <ScrollToTop />
          <TermsConditions />
        </Route>
        <Route path="/disclaimer">
          <ScrollToTop />
          <Disclaimer />
        </Route>
        <Route path="/trademarks">
          <ScrollToTop />
          <Trademarks />
        </Route>
        <Route path="/quick-start">
          <StepperProvider>
            <QuickGuide />
          </StepperProvider>
        </Route>
        <AuthRoute
          path="/join-us"
          component={
            (
              <StepperProvider>
                <SubmitForm />
              </StepperProvider>
            ) as any
          }
          isAuthenticated={!name && !loadingUserData}
          loading={loadingUserData}
        />

        <AuthRoute
          path="/admin"
          component={(<Admin />) as any}
          isAuthenticated={isAdmin && !loadingUserData}
          loading={loadingUserData}
        />
        <AuthRoute
          path={[
            '/dashboard',
            '/messages',
            '/activity',
            '/notifications',
            '/search',
          ]}
          component={(<Dashboard />) as any}
          isAuthenticated={
            !isNull(name) &&
            !loadingUserData &&
            !!localStorage.getItem('connectedWallet')
          }
          loading={loadingUserData}
        />
        <AuthRoute
          path="/profile"
          component={(<Profile />) as any}
          isAuthenticated={!!name && approvedMember && !loadingUserData}
          loading={loadingUserData}
        />
        <AuthRoute
          path="/mobile-warning"
          component={(<MobileWarningPage />) as any}
          isAuthenticated={isMobile}
          loading={false}
        />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
}
