export const aboutPage = {
  title: 'About ACRE DAOs',
  sections: [
    {
      paragraph: `Originally conceived in early 2021, ACRE DAOs began to take shape at a retreat off the coast of Mount Desert, Maine in June 2021. `,
    },
    {
      paragraph: `The attendees explored strategies for how decentralized autonomous organizations (DAOs) could form compliant cooperative investment models that allow for greater degrees of investor diversification and participation in place-based, values-aligned businesses and projects. `,
    },
    {
      paragraph: `We hope new kinds of organizational structures and economic incentives made possible by distributed ledger technology and smart contracts can activate greater synergy and capital liquidity among stakeholders.`,
    },
    {
      paragraph: `Our theory of change suggests that large numbers of people can effectively and dynamically organize into small groups for the funding of targeted regenerative solutions. `,
    },
    {
      paragraph: (
        <span>
          ACRE DAOs is a Web3 portal to such a community of change agents. We
          invite you to become a Founding Member during our open call to help
          re-imagine impact investing over the months and years to come. For
          more information, please see our FAQs or contact us via{' '}
          <a target="blank" href="https://twitter.com/acredaos">
            Twitter
          </a>
          ,{' '}
          <a target="blank" href="https://instagram.com/acredaos">
            Instagram
          </a>
          , or{' '}
          <a target="blank" href="https://discord.gg/acredaos">
            Discord
          </a>
          .
        </span>
      ),
    },
  ],
};
