export { faqQuestions } from './faqPage';
export { quickStartGuide } from './quickStartPage';
export { landingPage } from './landingPage';
export { footer } from './footerComponent';
export { privacyPolicy } from './privacyPolicyPage';
export { termsAndConditions } from './termsAndConditionsPage';
export { aboutPage } from './aboutPage';
export { cookiesPage } from './cookiesPage';
export { disclaimerPage } from './disclaimerPage';
export { trademarksPage } from './trademarksPage';

export enum CardDirection {
  'row',
  'column',
}

export interface PaddingInterface {
  padTop: number;
  padRight: number;
  padBottom: number;
  padLeft: number;
}
