import styled from 'styled-components';
import { screenSizes } from '../../../styles/screenSizes';

interface StyleProps {
  tall?: boolean;
}

export const Wrapper = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  margin: ${(props) =>
    props.tall
      ? 'calc((100 / var(--base-font-size) * 1em)) auto 0 auto'
      : '0 auto 0 auto'};
  min-height: calc(100vh - 265px - 3em - (100 / var(--base-font-size) * 1em));

  @media ${screenSizes.maxMobileL} {
    min-height: 100%;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    min-height: 100%;
  }
`;
