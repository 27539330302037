import { InjectedConnector as MetaMask } from '@web3-react/injected-connector';
import { NetworkConnector } from '@web3-react/network-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { PortisConnector } from '@web3-react/portis-connector';
import { LedgerConnector } from '@web3-react/ledger-connector';
import { FortmaticConnector } from '@web3-react/fortmatic-connector';
import { TrezorConnector } from '@web3-react/trezor-connector';

const POLLING_INTERVAL = 12000;
const RPC_URLS: { [chainId: number]: string } = {
  1: process.env.REACT_APP_INFURA_MAINNET as string,
  4: process.env.REACT_APP_INFURA_RINKEBY as string,
};
export const injected = new MetaMask({
  supportedChainIds: [1, 3, 4, 5, 42],
});

export const network = new NetworkConnector({
  urls: { 4: RPC_URLS[4], 1: RPC_URLS[1] },
  defaultChainId: 4,
});

export const walletConnect = new WalletConnectConnector({
  rpc: { 4: RPC_URLS[4], 1: RPC_URLS[1] },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

export const walletLink = new WalletLinkConnector({
  url: RPC_URLS[1],
  appName: 'Acre Dao',
});

export const ledger = new LedgerConnector({
  chainId: 1,
  url: RPC_URLS[1],
  pollingInterval: POLLING_INTERVAL,
});

export const portis = new PortisConnector({
  dAppId: process.env.PORTIS_DAPP_ID as string,
  networks: [4, 1],
});

export const fortmatic = new FortmaticConnector({
  apiKey: process.env.REACT_APP_FORTMATIC_KEY as string,
  chainId: 1,
});

export const trezor = new TrezorConnector({
  chainId: 1,
  url: RPC_URLS[1],
  pollingInterval: POLLING_INTERVAL,
  manifestEmail: 'dummy@dummy.com',
  manifestAppUrl: 'https://acredaos.com',
});
