import { H1, Body, Wrapper, Grid, ButtonWrapper, CloseButton } from './Style';
import { WALLETS } from '../../constants/wallets';
import WalletButton from '../WalletBtn';

interface WalletConnectionModalProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function WalletConnectionModal({
  onClose,
}: WalletConnectionModalProps) {
  const handleCloseClick = () => {
    onClose(false);
  };
  return (
    <Wrapper>
      <CloseButton>
        <i onClick={handleCloseClick} className="fa fa-close" />
      </CloseButton>
      <H1>Connect Your Wallet</H1>
      <Body>Connect your wallet to apply or login!</Body>
      <Grid>
        {WALLETS.map((wallet, idx) => (
          <ButtonWrapper key={wallet.connectorName + idx}>
            <WalletButton
              connectorName={wallet.connectorName}
              name={wallet.name}
              imgLink={wallet.image}
              onClose={onClose}
            />
          </ButtonWrapper>
        ))}
      </Grid>
    </Wrapper>
  );
}
