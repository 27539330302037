import { gql } from '@apollo/client';

export const GET_ALL_APPLICANTS = gql`
  query Query {
    applicants {
      id
      address
      name
      email
      discord
      approved
      denied
      createdAt
      isAdmin
      linkedIn
      twitter
      github
      instagram
      personalBlog
      organizationName
      organizationWebsite
    }
  }
`;

export const GET_APPLICANTS_BY_APPROVAL = gql`
  query Query($approved: Boolean!) {
    getApplicantsByApproval(approved: $approved) {
      id
      address
      name
      email
      discord
      approved
      createdAt
      isAdmin
      linkedIn
      twitter
      github
      instagram
      personalBlog
      organizationName
      organizationWebsite
    }
  }
`;

export const GET_INDIVIDUAL_APPLICANT = gql`
  query Query($address: String!) {
    applicant(address: $address) {
      id
      organizationName
      organizationWebsite
      about
      address
      name
      email
      createdAt
      phone
      imgUrl
      linkedIn
      twitter
      discord
      github
      instagram
      personalBlog
      other
      interests
      investor
      web3Enthusiast
      researcher
      projectLeader
      identifyOther
      approved
      isAdmin
      discourseUsername
    }
  }
`;
