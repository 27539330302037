import { useState } from 'react';

import {
  BadgesContainer,
  BadgesSelector,
  Button,
  CategoriesSelectorContainer,
  SelectCategoryPlaceholder,
  VisibleInput,
} from './Style';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useWeb3React } from '@web3-react/core';
import { InterestCategory } from '../../constants/interestCategories';
import { useLocation } from 'react-router';

interface WalletDropdownProps {
  address?: string;
}

export default function WalletDropdown({ address }: WalletDropdownProps) {
  const { deactivate } = useWeb3React();
  const location = useLocation();
  const [showBadgeSelector, setShowBadgeSelector] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<
    InterestCategory[]
  >([]);

  const disconnect = async () => {
    if (!showBadgeSelector) return;
    await deactivate();
    window.localStorage.setItem('connectedWallet', '');
    window.location.replace('/');
  };

  return (
    <CategoriesSelectorContainer>
      <ClickAwayListener
        onClickAway={() => {
          if (!showBadgeSelector) return;
          setShowBadgeSelector(false);
        }}
      >
        <VisibleInput>
          <BadgesContainer>
            {selectedCategories?.length === 0 && (
              <SelectCategoryPlaceholder
                onClick={() => setShowBadgeSelector(true)}
                dark={
                  location.pathname.includes('profile') ||
                  location.pathname.includes('dashboard')
                }
              >
                {address}
              </SelectCategoryPlaceholder>
            )}
          </BadgesContainer>
        </VisibleInput>
      </ClickAwayListener>
      <BadgesSelector show={showBadgeSelector}>
        <Button onClick={disconnect}>Disconnect</Button>
      </BadgesSelector>
    </CategoriesSelectorContainer>
  );
}
