import styled from 'styled-components';

interface StyleProps {
  show?: boolean;
}

export const Background = styled.div<StyleProps>`
  z-index: 1000000;
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(69, 138, 242, 0.6);
  backdrop-filter: blur(16px);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  position: fixed;
  width: auto;
  height: auto;
  border-radius: 10px;
  padding: 0.75rem;
  color: var(--color-red-600);
  margin: auto;
  z-index: 100000;
`;
