import styled from 'styled-components';

interface StyleProps {
  fill: 1 | 0;
  round: 'full' | string;
  hasText: boolean;
}

export const Wrapper = styled.div<StyleProps>`
  width: ${(props) => (props.hasText ? 'fit-content' : '36px')};
  height: 36px;
  padding: 8px ${(props) => (props.hasText ? '16px' : '8px')};
  background: ${(props) =>
    props.fill ? `var(--color-blue-800)` : 'transparent'};
  border: 2px solid var(--color-blue-800);
  border-radius: ${(props) => (props.round === 'full' ? '50%' : props.round)};
  display: flex;
  align-items: center;

  color: ${(props) =>
    props.fill ? 'var(--color-white)' : `var(--color-blue-800)`};
  font-weight: bold;
  font-family: var(--font-overpass);
  font-size: calc((16 / var(--base-font-size) * 1rem));
  line-height: calc((19.84 / var(--base-font-size) * 1rem));

  justify-content: ${(props) => (props.hasText ? `inherit` : 'center')};

  > i {
    font-size: calc((20 / var(--base-font-size) * 1rem));
    margin-right: ${(props) => (props.hasText ? '5px' : 0)};
  }

  cursor: pointer;
`;
