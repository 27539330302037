import styled from 'styled-components';
import { screenSizes } from '../../styles/screenSizes';
import { header3, bodyParagraph } from '../../styles/sharedStyles';

export const Wrapper = styled.div`
  width: 650px;
  height: 450px;
  background-color: var(--color-blue-50);
  box-shadow: -16px 24px 24px rgba(84, 124, 203, 0.3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const H1 = styled.h1`
  ${header3};
  color: var(--color-blue-800);
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

export const Body = styled.div`
  ${bodyParagraph};
  color: var(--color-blue-800);
`;

export const Grid = styled.div`
  display: grid;
  margin-top: 1em;
  grid-template-columns: repeat(2, minmax(230px, 1fr));
  grid-auto-rows: 112px;
  grid-gap: 10px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const CloseButton = styled.div`
  color: var(--color-blue-800);
  font-size: calc((30 / var(--base-font-size) * 1rem));
  margin-left: 93%;
  cursor: pointer;
  @media ${screenSizes.maxLaptop} {
    margin-left: 90%;
  }
`;
