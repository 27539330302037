import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /* Below is an example to create a globally available css variable. This is called in the individual component 
  like this: background: var(--color-background); */
  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600;700;800&family=Overpass:wght@100;200;300;400;600;700;800&display=swap');

  :root {
    //fonts
    --font-overpass: 'Overpass Regular';
    --font-tropical: 'tropical garden';
    --font-josefin: 'Josefin Sans', sans-serif;
    --base-font-size: 16;

    //Color Palette
    
    //Blue 
    --color-blue-25: #F4FAFF;
    --color-blue-50: #EFF7FF;
    --color-blue-100: #DDECFE;
    --color-blue-200: #C2DFFC;
    --color-blue-300: #99C8F9;
    --color-blue-400: #6CAAF7;
    --color-blue-500: #458AF2;
    --color-blue-600: #2D6BEA;
    --color-blue-700: #2157DA;
    --color-blue-800: #2148B8;
    --color-blue-900: #1F4098;
    --color-blue-radial-200-400: radial-gradient(100% 256% at 100% 0%, #C2DFFC 0%, #6CAAF7 68.75%);
    --color-blue-radial-100-400-800: radial-gradient(circle at 120% 50%, #ddecfe, #6caaf7, #2148b8);

    //Green
    --color-green-50: #F2FDF5;
    --color-green-100: #DDFDE5;
    --color-green-200: #BEF9CE;
    --color-green-300: #91F2A8;
    --color-green-400: #64E67D;
    --color-green-500: #4BD35C;
    --color-green-600: #37B745;
    --color-green-700: #269634;
    --color-green-800: #1B7B2A;
    --color-green-900: #146722;

    //Yellow
    --color-yellow-50: #FDFAEA;
    --color-yellow-100: #FDF8C8;
    --color-yellow-200: #FCF099;
    --color-yellow-300: #F9DF65;
    --color-yellow-400: #F3CC49;
    --color-yellow-500: #E1B33E;
    --color-yellow-600: #C28C30;
    --color-yellow-700: #9A6424;
    --color-yellow-800: #7E4F1F;
    --color-yellow-900: #6C411D;

    //Red
    --color-red-50: #FCF3F2;
    --color-red-100: #FAE3E2;
    --color-red-200: #F6CDCB;
    --color-red-300: #F1A8A6;
    --color-red-400: #E87874;
    --color-red-500: #DE524C;
    --color-red-600: #CB3931;
    --color-red-700: #AB2E27;
    --color-red-800: #8C2823;
    --color-red-900: #752622;

    //Gray
    --color-gray-50: #F9FAFB;
    --color-gray-100: #F3F4F5;
    --color-gray-200: #E6E7EB;
    --color-gray-300: #D2D5DC;
    --color-gray-400: #9DA2AD;
    --color-gray-500: #6C727F;
    --color-gray-600: #4E5563;
    --color-gray-700: #394151;
    --color-gray-800: #212937;
    --color-gray-900: #121826;

    --color-gray: #C4C4C4;
    --color-black: #000000;
    --color-white: #FFFFFF;

    //Categories
    --color-category-social-justice: #C38C30;
    --color-category-education: #D2D5DC;
    --color-category-financial-inclusion: #91F2AB;
    --color-category-health-services: #DE524C;
    --color-category-food-security: #458AF2;
    --color-category-climate-change: #99C8F9;
  }

  html {
    --fontSize-mobile: 2em;
    overflow-x: hidden;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  dl,
  dd {
    margin: 0;
    padding: 0;
  }

  ul[role='list'],
  ol[role='list'] {
    list-style: none;
  }

  body {
    background: var(--color-blue-radial-200-400);
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    font-family: -apple-system, BlinkMacSystemFont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  a {
    text-decoration: none;
  }

  img,
  picture {
    max-width: 100%;
    display: block;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  input[type=text]:focus:not([readonly]) {
    box-shadow: none;
    border-bottom: none;
    outline: none;
  }

  input:active {
    border-color: transparent;
    outline: 0;
  }

  ::-webkit-input-placeholder { /* Edge */
    color: var(--color-blue-200);
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-blue-200);
  }

  input::placeholder {
    color: var(--color-blue-200);
  }

  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
  
`;

export default GlobalStyle;
