import styled from 'styled-components';
import flex from '../../styles/mixins';
import { shadow } from '../../styles/sharedStyles';

export const CategoriesSelectorContainer = styled.div`
  ${flex('flex-start', 'flex-start', 'column')};
  position: relative;
`;

export const VisibleInput = styled.div`
  ${flex('center', 'center')};
  height: auto;
  background: transparent;
  svg {
    height: 100%;
    cursor: pointer;
    color: var(--color-blue-300);
    &.arrow {
      margin-right: 15px;
    }
  }
`;

export const BadgesContainer = styled.div`
  ${flex('flex-start', 'flex-start')};
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
`;

export const ChipCategory = styled.div<{ color?: string; border?: boolean }>`
  ${flex('center', 'center')};
  height: 28px;
  width: fit-content;
  padding: 0 15px;
  background: transparent;
  border-radius: 19px;
  font-weight: 600;
  margin: 10px;
  cursor: pointer;
  ${(props) => props.border && 'border: 1px solid #D2D5DC;'}
  color: ${(props) => props.color};
  svg {
    color: var(--color-gray-400);
    width: 15px;
    height: 100%;
    cursor: pointer;
    margin-left: 10px;
  }
  div {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 5px;
    font-size: calc((16 / var(--base-font-size) * 1rem));
    font-family: var(--font-overpass);
    background: ${(props) => props.color};
  }
`;

export const BadgesSelector = styled.div<{ show?: boolean }>`
  ${flex('center', 'flex-start')};
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  height: auto;
  min-height: 100px;
  background: transparent;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 0.2s ease-in-out;
  /* border: 2px solid var(--color-blue-100); */
  opacity: ${(props) => (props.show ? '1' : '0')};
  z-index: ${(props) => (props.show ? '1' : '-1')};
`;

export const SelectCategoryPlaceholder = styled.span<{ dark?: boolean }>`
  ${flex('center', 'center')};
  font-family: var(--font-overpass);
  font-size: calc((18 / var(--base-font-size) * 1rem));
  height: 100%;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 3px solid var(--color-blue-800);
  color: var(--color-blue-800);
  &:hover {
    opacity: 0.8;
  }
`;

export const Button = styled.button`
  ${shadow};
  font-size: calc((16 / var(--base-font-size) * 1rem));
  line-height: calc(23 / var(--base-font-size) * 1rem);
  padding: 3px 5px;
  background-color: transparent;
  font-family: var(--font-overpass);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  appearance: none;
  color: var(--color-blue-500);
  border: 2px solid var(--color-blue-500);
  outline: none;
  position: absolute;
  right: 12px;
  &:hover {
    opacity: 0.8;
  }
`;
