import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';
import Wallet from '../Wallet';
import { StyledMenu, MobileLinks, LaunchAppButton, Links } from './Style';

interface MobileMenuProps {
  open: boolean;
  isMobile: boolean;
  setOpen: Function;
}

const MobileMenu = ({ open, setOpen, isMobile }: MobileMenuProps) => {
  const { name } = useUserContext();
  const location = useLocation();

  return (
    <StyledMenu
      open={open}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
    >
      <MobileLinks>
        <Links href="/about">About</Links>
        <Links href="https://forum.acredaos.com/" target="_blank">
          Forum
        </Links>
        <Links href="https://faq.acredaos.com/acre-daos-faqs/" target="_blank">
          FAQ
        </Links>
      </MobileLinks>
      {!!name &&
        location.pathname !== '/profile' &&
        location.pathname !== '/dashboard' && (
          <Link to={isMobile ? '/mobile-warning' : '/dashboard'}>
            <LaunchAppButton marginBottom="10px">Launch App ›</LaunchAppButton>
          </Link>
        )}
      <Wallet />
    </StyledMenu>
  );
};
export default MobileMenu;
