import { Dispatch, MutableRefObject, useEffect, useRef } from 'react';

export const useClickOutside = (callback: () => void) => {
  const innerRef: MutableRefObject<any> = useRef();

  const onClick = (event: any) => {
    if (innerRef?.current && !innerRef.current.contains(event.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', onClick, true);
    return () => {
      document.removeEventListener('click', onClick, true);
    };
  });
  return { innerRef };
};
